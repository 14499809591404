<template>
    <div class="form-elements">
        <vuestic-widget :headerText="$t('view.workteam.title')">
            <div class="row">
                <div class="col-md-3">
                    <button type="button" v-on:click="back" class="btn btn-primary btn-sm">{{'view.workteam.back' | translate}}</button>
                </div>
                <!--<div class="col-md-3 offset-md-6">-->
                <!--<button type="button" v-on:click="trashWorkteam" class="btn btn-warning btn-sm">Delete Element</button>-->
                <!--</div>-->

            </div>

        </vuestic-widget>
        <div class="row">
            <div class="col-md-12">
                <vuestic-widget :headerText="'view.workteam.update' | translate">
                    <form @submit.prevent="editWorkteam" autocomplete="off">
                        <div class="row">
                            <div class="col-md-12">
                                <fieldset>
                                    <div class="row">
                                        <div class="col-md-4 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('name'), 'valid': isSuccessNameValid}">
                                                <div class="input-group">
                                                    <input id="name" name="name" v-model="name" v-validate="'required'" />
                                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                    <label class="control-label" for="name">{{'view.workteam.fields.name' | translate}} </label><i class="bar"></i>
                                                    <small v-show="errors.has('name')" class="help text-danger">
                                                        {{ errors.first('name') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('email'), 'valid': isSuccessfulEmailValid}">
                                                <div class="input-group">
                                                    <input id="email" name="email" v-model="email" v-validate="'required|email'" />
                                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                    <label class="control-label" for="email">{{'view.workteam.fields.email' | translate}} </label><i class="bar"></i>
                                                    <small v-show="errors.has('email')" class="help text-danger">
                                                        {{ errors.first('email') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('roles_teams')}">
                                                <div class="input-group">
                                                    <multiselect
                                                        v-model="rol"
                                                        deselect-label="Selected Element"
                                                        label="name"
                                                        placeholder="Select an Element"
                                                        :options="rolesTeams"
                                                        :searchable="false"
                                                        :allow-empty="false"
                                                        >
                                                        <template slot="singleLabel" slot-scope="{ option }">
                                                          {{'view.workteam.fields.roles_teams' | translate}}
                                                          <label style="font-weight:600">{{ option.name }}</label>
                                                        </template>
                                                    </multiselect>
                                                </div>
                                            </div>
                                        </div>
                                        <!---->
                                        <!--<div class="col-md-6">-->
                                        <!--<div class="form-group with-icon-right" :class="{'has-error': errors.has('name'), 'valid': isSuccessfulNameValid}">-->
                                        <!--<div class="input-group">-->
                                        <!--<input id="name" name="name" v-model="name" v-validate="'required'" />-->
                                        <!--<i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>-->
                                        <!--<i class="fa fa-check valid-icon icon-right input-icon"></i>-->
                                        <!--<label class="control-label" for="name">{{'view.workteam.fields.name' | translate}} </label><i class="bar"></i>-->
                                        <!--<small v-show="errors.has('name')" class="help text-danger">-->
                                        <!--{{ errors.first('name') }}-->
                                        <!--</small>-->
                                        <!--</div>-->
                                        <!--</div>-->
                                        <!--</div>-->
                                        <!--<div class="col-md-6 with-icon-right">-->
                                        <!--<div class="form-group with-icon-right" :class="{'has-error': errors.has('email'), 'valid': isSuccessfulEmailValid}">-->
                                        <!--<div class="input-group">-->
                                        <!--<input id="email" name="email" v-model="email" v-validate="'required|email'" />-->
                                        <!--<i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>-->
                                        <!--<i class="fa fa-check valid-icon icon-right input-icon"></i>-->
                                        <!--<label class="control-label" for="email">{{'view.workteam.fields.email' | translate}} </label><i class="bar"></i>-->
                                        <!--<small v-show="errors.has('email')" class="help text-danger">-->
                                        <!--{{ errors.first('email') }}-->
                                        <!--</small>-->
                                        <!--</div>-->
                                        <!--</div>-->
                                        <!--</div>-->
                                    </div>
                                </fieldset>
                                <div class="col-md-3 offset-md-12">
                                    <button class="btn btn-primary btn-sm" type="submit">{{$t('view.workteam.update')}}</button>
                                </div>
                            </div>
                        </div>

                    </form>
                </vuestic-widget>
                <vuestic-widget :headerText="'view.workteam.password_update' | translate">
                    <form @submit.prevent="updateWorkteamPassword" autocomplete="off">
                        <div class="row">
                            <div class="col-md-12">
                                <fieldset>
                                    <div class="row">
                                        <div class="col-md-4 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('name'), 'valid': isSuccessNameValid}">
                                                <div class="input-group">
                                                    <input id="password" name="password" v-model="password" />
                                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                    <label class="control-label" for="name">{{'view.workteam.fields.password' | translate}} </label><i class="bar"></i>
                                                    <small v-show="errors.has('password')" class="help text-danger">
                                                        {{ errors.first('password') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 with-icon-right">
                                            <div class="form-group with-icon-right" :class="{'has-error': errors.has('email'), 'valid': isSuccessfulEmailValid}">
                                                <div class="input-group">
                                                    <input id="password2" name="password2" v-model="password2" />
                                                    <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                                                    <i class="fa fa-check valid-icon icon-right input-icon"></i>
                                                    <label class="control-label" for="email">{{'view.workteam.fields.password_confirmation' | translate}} </label><i class="bar"></i>
                                                    <small v-show="errors.has('password2')" class="help text-danger">
                                                        {{ errors.first('password2') }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 with-icon-right">
                                           
                                        </div>
                                      
                                    </div>
                                </fieldset>
                                <div class="col-md-3 offset-md-12">
                                    <button class="btn btn-primary btn-sm" type="submit">{{$t('view.workteam.update')}}</button>
                                </div>
                            </div>
                        </div>

                    </form>
                </vuestic-widget>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Update_Workteam',
  components: {
    Multiselect
  },
  data () {
    return {
      name: '',
      email: '',
      rol: '',
      rolesTeams: [],
      password:'',
      password2:'',
    }
  },
  mounted () {
    var id = this.$route.params.id
    if (id !== null) {
      this.loadWorkteam(id)
        .then((data) => {
          this.name = data.name
          this.email = data.email
          this.rol = data.company_team.roles
        })
        .catch(data => {
          this.addToastMessage({
            text: 'Ocurrio un error',
            type: 'success'
          })
        })
    } else {
      this.addToastMessage({
        text: 'Ocurrio un error',
        type: 'success'
      })
    }
    this.loadRolesWorkTeam()
      .then((data) => {
        this.rolesTeams = data
      })
      .catch(data => {
        this.error = data.message
      })
  },
  methods: {
    ...mapActions(['loadWorkteam', 'loadRolesWorkTeam', 'updateWorkteam', 'deleteWorkteam', 'addToastMessage','updatePasswordAdminWorkteam']),
    back () {
      this.$router.go(-1)
    },
    editWorkteam () {
      this.$validator.validate().then(result => {
        if (result) {
          var id = this.$route.params.id
          this.updateWorkteam({
            name: this.name,
            email: this.email,
            id_default_rol: this.rol.id,
            id: id
          })
            .then((data) => {
              this.addToastMessage({
                text: data.message,
                type: 'success'
              })
              setTimeout(() =>   this.$router.replace('/workteam'), 2000)
            })
            .catch(data => {
              this.error = data.message
              this.errors = data.errors || {}
            })
          // this.$refs.vuetable.refresh();
        }
      })
    },
    updateWorkteamPassword () {
      this.$validator.validate().then(result => {
        if (result) {
          
          if(this.password != this.password2){
            this.addToastMessage({
                text: 'Las contraseñas deben coincidir',
                type: 'warning'
              })
          }else{
            var id = this.$route.params.id
          this.updateWorkteam({
            password: this.password,
            confirm_password: this.password2,
            id: id
          })
            .then((data) => {
              console.log(data);
              if(data.code == 401){
                this.addToastMessage({
                text: data.message,
                type: 'warning'
              })
              store.commit('STOP_LOADING')

              }else{
                this.addToastMessage({
                text: data.message,
                type: 'success'
              })
              setTimeout(() =>   this.$router.replace('/workteam'), 2000)
              }
             
             
            })
            .catch(data => {
              this.error = data.message
              this.errors = data.errors || {}
            })
          }
        
        
          // this.$refs.vuetable.refresh();
        }
      })
    },
    cleanform () {
      this.name = null
      this.email = null
    }

  },
  computed: {
    ...mapState({
      company: state => state.auth.company
    }),
    isSuccessNameValid () {
      let isValid = false
      if (this.formFields.name) {
        isValid = this.formFields.name.validated && this.formFields.name.valid
      }
      return isValid
    },
    isSuccessfulEmailValid () {
      let isValid = false
      if (this.formFields.email) {
        isValid = this.formFields.email.validated && this.formFields.email.valid
      }
      return isValid
    },
  },
}
</script>
